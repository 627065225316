const FAQ = (props) => {
  return (
    <div className="w-full space-y-4 border-2 rounded-md m-0 p-6 text-left leading-relaxed font-B612 flex flex-col">
      <div className="flex  mb-4">
        <div className="w-24 text-left text-2xl font-bold">FAQs</div>
        <div className="text-2xl w-full text-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
      </div>
      <div className="text-xl font-bold">How TF do I get $MATIC?</div>
      <div className="text-md">Ask in <code>#welcome</code> discord channel someone will be willing to help you with the text: <code>"$MATIC Request: wallet_address".</code></div>
      <div className="text-xl font-bold">It says "No valid wallet address found"</div>
      <div className="text-md">Add your wallet address to the bottom of your about section in your profile in the following format: <code>"YC Badge: wallet_address".</code></div>
      <div className="text-xl font-bold">Does this work on mobile?</div>
      <div className="text-md">It should but some members have been having issues, please ask in #welcome for help or use a desktop computer.</div>
      <div className="text-xl font-bold">Something else?</div>
      <div className="text-md">Ask for more help in <code>#welcome</code> or DM <a href="https://twitter.com/orangedaoxyz">@OrangeDAOxyz</a>.</div>
    </div>
  );
};

export default FAQ;
