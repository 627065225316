require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);


const contractABI = require('../contract-abi.json')
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const walletString = "Connected: " +
        String(addressArray[0]).substring(0, 6) +
        "..." +
        String(addressArray[0]).substring(38);
      const obj = {
        status: walletString,
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            You must install a wallet like Metamask for your browser.
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        const walletString = "Connected: " +
          String(addressArray[0]).substring(0, 6) +
          "..." +
          String(addressArray[0]).substring(38);
        return {
          address: addressArray[0],
          status: walletString,
        };
      } else {
        return {
          address: "",
          status: "Connect your wallet to get started.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            You must install a wallet like Metamask for your browser.
          </p>
        </span>
      ),
    };
  }
};

export const checkOwnsBadge = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  const accounts = await window.ethereum.request({ method: 'eth_accounts' });
  const badgeCount = await contract.methods.balanceOf(accounts[0]).call();
  return badgeCount > 0;

  //without enumberability the below check for the exact badge id the wallet holds is too expensive
  /*if(badgeCount > 0){
    const badgeSupply = 10000 //this has to be hard coded since we don't have a method for querying total minted tokens. if their balance is > 0 we should never hit a non-valid id.
    for(var i = 1; i <= badgeSupply; i++){
      const currBadgeOwner = await contract.methods.ownerOf(i).call();
      if(currBadgeOwner.toLowerCase() === accounts[0].toLowerCase()){
        return i;
    }
    }
  }
  else
    return null;
  */
}

export const checkYCStatus = async (hackernewsUsername) => {
  const res = await fetch(`${process.env.REACT_APP_ORACLE_SERVER}/yc_badge?username=${hackernewsUsername}`);
  return await res.json();
}

export const checkAndSetPolygonNetwork = async () => {
  const useMainnet = process.env.NODE_ENV === 'production';
  const polygonChainId = useMainnet ? '0x89' : '0x13881';

  if (window.ethereum.chainId !== polygonChainId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: polygonChainId }]
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          const polgonRpcUrl = useMainnet ? 'https://polygon-rpc.com' : 'https://matic-mumbai.chainstacklabs.com';
          const polygonChainName = `Polygon ${useMainnet ? 'Mainnet' : 'Testnet Mumbai'}`;
          const blockExplorerUrl = useMainnet ? 'https://polygonscan.com/' : 'https://mumbai.polygonscan.com/';

          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainName: polygonChainName,
              chainId: polygonChainId,
              rpcUrls: [polgonRpcUrl],
              blockExplorerUrls: [blockExplorerUrl],
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
            }],
          });
        } catch (addError) {
          throw (addError)
        }
      }
      else
        throw (switchError)
    }
  }
}

export const verifyYCStatus = async (hackernewsUsername) => {
  const serverResponse = await checkYCStatus(hackernewsUsername);
  if (serverResponse.error) {
    return {
      success: false,
      status: "❗Error: " + serverResponse.error,
    };
  } else {
    return {
      success: true,
      status: "Success",
      voucher: serverResponse
    }
  }
}

export const mintBadge = async (voucher) => {
  try {
    //await checkAndSetPolygonNetwork();
    const contract = await new web3.eth.Contract(contractABI, voucher.contract_address);
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const transactionParameters = {
      to: voucher.contract_address,
      from: accounts[0],
      'data': contract.methods.alumniMint(voucher.voucher).encodeABI(),
      gas: '0x3D090', // 250,000 - fixes Brave issue where gas limit defaults to 0
    };
    const txHash = await window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });

    return {
      success: true,
      transactionHash: txHash,
      status: "✅ Check out your transaction on Polygonscan: https://polygonscan.com/tx/" + txHash
    }
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message
    }
  }
}
