import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintBadge,
  checkOwnsBadge,
  verifyYCStatus
} from "./utils/interact.js";
import FAQ from "./components/FAQ";

const InstallWallet = (props) => {
  return <div className="text-md text-left leading-relaxed font-B612">Before we start, please make sure you have <a href="https://metamask.io/">Metamask</a> or similar crypto wallet installed.
    If you're new to crypto please <a href="https://orangedao.notion.site/Rabbit-Hole-f9642371a4cb43f1955f00e634b2e0b5">read this article</a>. When ready, follow the steps below.
    <button className="w-full" id="mintButton" onClick={(e) => {
      e.preventDefault();
      window.location.href = 'https://metamask.io/download';
    }}> Install Metamask </button>
  </div>
    ;
};

const ConnectWallet = (props) => {
  return <div>
  
    <button className="shadow-md hover:shadow-xl bg-blue-600 text-white" id="connectButton" onClick={connectWallet}>
      Connect your wallet
    </button>
    <div className="flex flex-wrap md:flex-nowrap text-left md:space-x-2 space-y-2 md:space-y-0 py-10 text-sm">
      <div className="w-full md:w-1/2 space-y-4 border-2 rounded-md m-0 p-6 text-left leading-relaxed font-B612 flex flex-col">
        <div className="text-2xl w-full text-center"><svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
          <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
        </svg></div>
        <div className="w-full text-center font-bold">Perks for Holders</div>
        <div>The gem is a way to declare you're a YC founder on chain. Using just your wallet, apps can verify your YC affiliation and offer you exclusive access:</div>
        <div>- Join token-gated servers like the Orange DAO discord.</div>
        <div>- Discounts and early access.</div>
        <div>- Special access to NFT drops.</div>

      </div>

      <div className="w-full md:w-1/2 space-y-4 border-2 rounded-md m-0 p-6 text-left leading-relaxed font-B612 flex flex-col">
        <div className="text-2xl w-full text-center"><svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg></div>
        <div className="w-full text-center font-bold">For Builders</div>
        <div>Tap into a community of founders and startups eager to try new products. All Gem holders are verified YC founders. Add Gem support to your app:</div>
        <div>- Use the <a href="https://polygonscan.com/address/0xc1c6a331d4013f40ca830c06ed47564d0d2b21cd#code">Gem Contract</a> on Polygon Mainnet to check for token ownership.</div>
        <div>- Want to learn more about gem integration? DM us <a href="https://twitter.com/OrangeDAOxyz">@OrangeDAOxyz</a></div>
      </div>
    </div>

  </div>;
};

const VerifyAccount = (props) => {
  const [buttonStatus, setButtonStatus] = useState("Verify");
  const [hackernewsUsername, setHackernewsUsername] = useState("");

  const verify = async () => {
    setButtonStatus("Verifying...");
    try {
      const result = await verifyYCStatus(hackernewsUsername);
      if (result.success) {
        props.setTransactionHash(result.voucher.tx_hash);
      } else {
        props.setError("Verification failed: " + result.status);
      }
    } catch (e) {
      props.setError("Account verification failed: " + e.message);
    }
  };

  return <div>

    <div className="w-full border-2 rounded-md mb-4 p-6 text-left leading-relaxed font-B612 flex flex-col">
      <div className="w-full flex">
        <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
        <div className="w-full text-left font-bold">Edit your HN profile.</div>
      </div>
      <div>Go to your profile, and add the following text to the bottom of the About field. It already has your unique wallet address.</div>
      <div>
        <div className="font-mono text-xs m-4 p-2 bg-gray-200">YC Badge: {props.walletAddress}</div>
      </div>
      When you are done, come back and provide your HN username below.
      <div><strong>Note:</strong> Your HackerNews ID is not stored as plaintext in the contract, token, or artwork. There is a function that someone can use to lookup HackerNews usernames to find gem id called <a hreaf="https://polygonscan.com/address/0xc1c6a331d4013f40ca830c06ed47564d0d2b21cd#readProxyContract">hnIdToTokenId</a></div>
    </div>

    <form>
      <input
        className="w-full h-12 border-2 border-gray-100 text-center"
        type="text"
        placeholder="Your hackernews username here"
        onChange={(event) => setHackernewsUsername(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            verify();
          }
        }}
      />
    </form>
    <button className="w-full" id="mintButton" onClick={verify} disabled={buttonStatus === "Verifying..." || buttonStatus === "Verified"}>
      {buttonStatus}
    </button>
  </div>;
};

const MintGem = (props) => {
  const [buttonStatus, setButtonStatus] = useState("Mint Orange Gem");

  const onMintPressed = async () => {
    try {
      setButtonStatus("Minting...");
      const mintResponse = await mintBadge(props.voucher);
      props.setStatus(mintResponse.status);
      if (mintResponse.success) {
        props.setTransactionHash(mintResponse.transactionHash);
      }
    } catch (e) {
      props.setError("Mint failed: " + e.message);
    }
  };

  return <div>
    <div className="flex flex-wrap md:flex-nowrap text-left md:space-x-2 space-y-2 md:space-y-0 text-sm">
      <div className="w-full space-y-4 border-2 rounded-md m-0 p-6 text-left leading-relaxed font-B612 flex flex-col">
        <div className="text-2xl w-full text-center"><svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg></div>
        <div className="w-full text-center font-bold">Add $MATIC funds to pay for gas.</div>
        <div>Three options we recommend:</div>
        <div>- Use <a href="https://macncheese.finance/matic-polygon-mainnet-faucet.php">matic.supply</a> to get a small amount of $MATIC for free.</div>
        <div>- Use an exchange like <a href="https://dharma.io/">Dharma.io</a> (see <a href="https://polygon.technology/matic-token/">other options</a>), then transfer those funds to your wallet.</div>
      </div>
    </div>
    <button className="w-full" id="mintButton" onClick={onMintPressed} disabled={buttonStatus === "Minting..." || buttonStatus === "Minted"}>
      {buttonStatus}
    </button>
  </div>;
};

const MintSuccess = (props) => {
  const transactionUrl = "https://polygonscan.com/tx/" + props.transactionHash;

  if (props.ownsBadge) {
    const openseaWalletUrl = `https://${process.env.NODE_ENV === 'production' ? '' : 'testnets.'}opensea.io/${props.walletAddress}`;
    return <div className="text-md text-left leading-relaxed font-B612">🍊 Your badge is in your wallet. Check it out on OpenSea:
      <a href={openseaWalletUrl}> {openseaWalletUrl}</a>
    </div>;
  }
  else {
    const openseaCollectionUrl = `https://${process.env.NODE_ENV === 'production' ? '' : 'testnets.'}opensea.io/collection/alumni-gems`;
    return <div>
      <div className="text-md text-left leading-relaxed font-B612">🍊 Gem minted! Here's the transaction:
      <a href={transactionUrl}>{transactionUrl}</a>
      </div>
      <br></br>
      <div className="text-md text-left leading-relaxed font-B612">  In about a minute, your Gem will appear on OpenSea when the transaction is complete:
        <a href={openseaCollectionUrl}>{openseaCollectionUrl}</a>
      </div>
      </div>;
  }
};

const MintFailure = (props) => {
  const { errorMessage } = props;
  return (
    <>
      <div className="text-md text-left leading-relaxed font-B612">{errorMessage}</div>
      <button onClick={() => window.location.reload()} className="text-md text-left leading-relaxed font-B612">Click here to reload the page</button>
      <FAQ />
    </>
  );
};

/*
  Minter Component. 
  The stage state variable has the following possible values:
  0 - checks for existence of a wallet, if yes show button to connect, if no show link to MM download
  1 - once a wallet is detected, show the "Connect Wallet" button
  2 - once connected, if no YC gem show string to paste to HN and a "verify" button, if YC gem skip to stage 4
  3 - show "Mint YC GEM", on success move to stage 4, on failure move to stage 5
  4 - show success
  5 - show failure message and FAQ
*/
const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [voucher, _setVoucher] = useState({});
  const [transactionHash, _setTransactionHash] = useState("");
  const [ownsBadge, _setOwnsBadge] = useState(0);
  const [stage, setStage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const setVoucher = async (voucher) => {
    _setVoucher(voucher);
    setStage(3);
  };

  const setOwnsBadge = async (ownsBadge) => {
    _setOwnsBadge(ownsBadge);
    setStage(4);
  }

  const setTransactionHash = async (transactionHash) => {
    _setTransactionHash(transactionHash);
    setStage(4);
  };

  const setErrorFromStage = (error) => {
    setErrorMessage(error);
    setStage(5);
  }

  // Runs only on first load
  useEffect(() => {
    async function initialSetup() {
      try {
        addWalletListener();
        const { address, status } = await getCurrentWalletConnected();
        setWallet(address);
        const ownsBadge = address ? await checkOwnsBadge() : null;

        if (ownsBadge) setOwnsBadge(ownsBadge);
        else if (address.length > 0) {
          setStage(2);
        } else {
          if (window.ethereum) {
            setStage(1);
          } else {
            setStage(0);
          }
        }
      } catch (e) {
        setErrorMessage("Initial load failed: " + e.message);
        setStage(5);
      }
    }
    initialSetup()
  }, []);


  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          //const alreadyMinted = address ? await hasBadgeAlready() : false;
          const alreadyMinted = false;
          if (alreadyMinted) {
            setStatus("Successfully connected wallet.");
          } else {
            setStage(2);
          }
          setWallet(accounts[0]);
          setStatus("Successfully connected wallet.");
        } else {
          setWallet("");
          setStatus("Connect your wallet to mint your Orange Gem.");
          setStage(1);
        }
      });
    } else {
      setStatus(
        <p>
          You must install a wallet like Metamask for your browser.
        </p>
      );
      setStage(0);
    }
  }

  return (
    <div className="min-h-screen h-full relative flex flex-col items-center md:justify-center bg-yc">
      <header className="w-full absolute top-0 h-14">
        <div className="flex top-0 items-center justify-between pr-4 pl-4 h-12">
          <div className="flex font-bold">
            🍊 <span className="text-yellow-700">Orange</span>DAO
          </div>
          <div className="uppercase text-xs text-right text-blue-600" id="status">
            {status}
          </div>
        </div>
      </header>

      <div className="max-w-screen-md bg-white rounded-3xl w-11/12 mt-16 md:mt-0 md:p-6 pb-12 py-12 space-y-8 bg-yc">
        <div className="text-3xl text-left leading-relaxed font-bold font-B612 flex-col">
          <div className="text-xl text-gray-500 font-sans">Hello YC Alum,</div>
          <div>Let's mint your Orange Gem!</div>
          <div className="text-xl text-gray-500 font-sans">Your Orange DAO community access token.</div>
        </div>

        {stage === 0 && <InstallWallet setState={setStage} />}
        {stage === 1 && <ConnectWallet />}
        {stage === 2 && <VerifyAccount walletAddress={walletAddress} setTransactionHash={setTransactionHash} setError={setErrorFromStage} />}
        {stage === 3 && <MintGem voucher={voucher} setTransactionHash={setTransactionHash} setStatus={setStatus} setError={setErrorFromStage} />}
        {stage === 4 && <MintSuccess transactionHash={transactionHash} ownsBadge={ownsBadge} walletAddress={walletAddress}/>}
        {stage === 5 && <MintFailure errorMessage={errorMessage} />}

        <div className="flex flex-wrap md:flex-nowrap text-left md:space-x-2 space-y-2 md:space-y-0 text-sm">

        </div>
      </div>
      <footer className="w-full font-light flex items-center justify-center text-xs absolute bottom-0 h-12">
        Made with 🍊 by Orange DAO.
      </footer>
    </div>
  );
};

export default Minter;
