import './App.css';
import Minter from './Minter'

function App() {
  return (
    <Minter></Minter>
  );
}

export default App;
